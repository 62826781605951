<template>
  <div class="money-log-view">
    <div v-if="!$store.state.isMobileLayout" class="types">
      <div class="type-title">額度轉換</div>
    </div>
    <div
      :class="[$store.state.isMobileLayout ? 'full-content' : '']"
    >
      <div class="top flex-ac flex-jb">
        <div v-if="$store.state.isMobileLayout" class="control relative">
          <div
            class="btn type-btn active"
            @click="is_show_option = !is_show_option"
          >
            額度轉換
          </div>
        </div>
      </div>
      <dev v-if="step_1" class="able-row flex-ac flex-jb log-item">
        <p class="enter-title">請選擇轉出帳號 :
          <select style="background: none;opacity: 1;" v-model="outputAccount"> 
            <option value="現金網">現金網({{userInfo.totalBalance}})</option>
            <option v-for="item in $store.state.gamemoney" v-if="!['--', '0'].includes(item.surBalance)" v-bind:value="item.game_name">{{item.game_name}}({{item.surBalance}})</option>
            </select>
           <div class="type-btn btn shadow active"
             @click="changeStep(false, true, false)"
           >下一步</div>
        </p>
      </div>
      <div v-if="step_2" class="able-row flex-ac flex-jb log-item">
        <p class="enter-title">請選擇轉入帳號 :
          <select style="background: none;opacity: 1;" v-model="inputAccount"> 
            <option v-if="![outputAccount].includes('現金網')" value="現金網">現金網({{userInfo.totalBalance}})</option>
            <option v-for="item in $store.state.gamemoney" v-if="['現金網'].includes(outputAccount)" v-bind:value="item.game_name">{{item.game_name}}({{item.surBalance}})</option>
            </select>
           <div class="type-btn btn shadow active"
             @click="changeStep(true, false, false)"
           >上一步</div>
           <div class="type-btn btn shadow active"
             @click="changeStep(false, false, true)"
           >下一步</div>
        </p>
      </div>
      <a-spin :spinning="loading">
      <div v-if="step_3" class="able-row flex-ac flex-jb log-item">
       <div class="detail" style="text-align:left;">
        <p class="enter-title">請選擇轉出帳號 : {{outputAccount}}</p>
        <p class="enter-title">請選擇轉入帳號 : {{inputAccount}}</p>
        <p class="enter-title">請輸入轉換金額 : <input type="text" v-model="inputValue" style="background: none;opacity: 1;" v-on:input="checkInputValue"></p>
        <p class="enter-title" v-bind:style="{ color: outputColor }">[{{outputAccount}}]持有金額 : {{money}}</p>
        <p class="enter-title" v-bind:style="{ color: inputColor }">[{{inputAccount}}]轉換後金額 : {{inputMoney}}</p>
        <div class="able-row flex-ac flex-jb log-item">
           <div class="type-btn btn shadow active"
             @click="changeStep(flase, true, false)"
           >上一步</div>
           <div class="type-btn btn shadow active"
             @click="confirm()"
           >確認</div>
        </div>
       </div>
       </a-pin>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios'
import pagination from '@/mixins/pagination'
import usr from '@/mixins/usr'

export default {
  name: 'transfer_money',
  mixins: [pagination, usr],
  components: {
  },
  data() {
    return {
      loading: false,
      operation_type: '',
      is_show_option: false,
      outputAccount: '現金網',
      inputAccount: '',
      money: '0',
      afterMoney: 0,
      totalMoney: 0,
      inputMoney: 0,
      inputValue: 0,
      outputColor: '',
      inputColor: '',
      step_1: true,
      step_2: false,
      step_3: false,
      month_list: [],
      list: [],
      show_pages: false
    }
  },
  async created() {
    await this.getUserInfo()
  },
  methods: {
    async confirm(){
        if(this.inputColor == 'red' || this.outputColor == 'red' || this.inputValue <= 0) return
        var checkType = 1
        var gamename = ''
        var gameId = ''
        if("現金網" == this.outputAccount){
            checkType = 0 
            gamename = this.inputAccount
        }
        if("現金網" == this.inputAccount){
          checkType = 1
          gamename = this.outputAccount
        }
        
        for (let i = 0; i < this.$store.state.gamemoney.length; i++) {
          var item = this.$store.state.gamemoney[i]
          if(gamename == item.game_name){
            gameId = item.game_id
            break
          }        
        }

        const mapStr = JSON.stringify({
          game_id: gameId,
          balance: this.inputValue,
          checkType: checkType,
          status: checkType
        })
     
        try {
          this.loading = true
          const { code, info, data } = await post(
            'quota_addQuotaConversion.do',
            this.Qs.stringify({ mapStr })
          )
          let res = JSON.parse(data)
          if(res != null && res['flag'] == 0){
            alert('額度轉換成功')
          } else {
            alert('額度轉換異常，請稍後再試')
          }
        } catch {
            alert('額度轉換異常，請稍後再試')
        } finally {
          this.getUserInfo()
          this.outputAccount = ''
          this.inputValue = 0
          this.changeStep(true, false, false)
          this.loading = false
        }
    },
    changeStep(t1, t2, t3){
        this.step_1 = t1
        this.step_2 = t2
        this.step_3 = t3

        if(t3){
          if("現金網" == this.outputAccount){
            this.totalMoney = this.userInfo.totalBalance
          }
          if("現金網" == this.inputAccount){
            this.afterMoney = this.userInfo.totalBalance
          }
          for (let i = 0; i < this.$store.state.gamemoney.length; i++) {
            var item = this.$store.state.gamemoney[i]
            if(this.outputAccount == item.game_name){
              this.totalMoney = item.surBalance
            }        
            if(this.inputAccount == item.game_name){
              this.afterMoney = item.surBalance
            }        
          }
          this.money = this.totalMoney
          if(this.afterMoney === "--"){
            this.afterMoney = 0
          }
          this.inputMoney = this.afterMoney 
        }
    },
    checkInputValue() {
      if(this.inputValue.length === 0) {
        this.inputMoney = '請輸入數字[' + this.afterMoney + ']'
        this.inputColor = 'red'
        this.money = this.totalMoney
      } else if(!isNaN(this.inputValue)) {
        if(this.inputValue < 0){
            this.inputMoney = '請輸入正確數字[' + this.afterMoney + ']'
            this.money = this.totalMoney
            this.inputColor = 'red'
        } else {
            this.inputMoney = parseInt(this.afterMoney) + parseInt(this.inputValue)
            this.money = this.totalMoney - this.inputValue
            if(this.inputMoney < 0 ){
              this.inputColor = 'red'
            } else {
              this.inputColor = 'green'
            }
            if(this.money < 0 ){
              this.outputColor = 'red'
            } else {
              this.outputColor = 'green'
            }
        }
      } else {
        this.money = this.totalMoney
        this.inputMoney = '您輸入的不是數字[' + this.afterMoney + ']'
        this.inputColor = 'red'
      }        
    },
  },
  computed: {
    isAbout() {
      return this.$route.fullPath.indexOf('/about') > -1
    },
    routeName() {
      if (!this.isAbout) {
        return this.$route.meta.name
      } else {
        const target = tabs.find(tab => tab.name === this.$route.params.type)
        if (target) {
          return target.label
        }
        return target ? target.label : this.$route.meta.name
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.header_title = this.routeName
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/variables.scss';

.money-log-view {
  padding: 2rem 2%;
}

.option-modal {
  position: absolute;
  width: 100%;
  top: 2.2rem;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  min-width: 4rem;

  .label {
    color: #999;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    padding: 0.5rem;
  }

  .option {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1rem;
    cursor: pointer;

    &.active {
      color: $theme-color;
    }
  }
}

.select {
  background: transparent;
  color: white;
  border: none;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;

  > option {
    color: black;
    text-align: center;
  }
}

.top {
  width: 100%;
}

.table {
  width: 100%;
}

.types {
  width: 8rem;
  display: flex;
  flex-direction: column;
}

.type-title {
  color: white;
  margin: 0.5rem 0 1rem;
  font-size: 1rem;
}

.type-btn {
  color: white;
  margin-bottom: 0.8rem;
  cursor: pointer;
  letter-spacing: 10px;
}

.right-content {
  flex: 1;
  margin-left: 0.5rem;
}

.table-row {
  width: 100%;
  font-size: 0.8rem;
  margin-bottom: 1px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:last-child {
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
  }
}

.bottom {
  width: 100%;
  height: 6rem;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.hint {
  width: 50%;
  margin: 0 auto 1rem;
  line-height: 1.5;
  font-size: 0.8rem;
  color: #999;
  text-align: left;
}

.pages {
  position: relative;
  font-size: 0.9rem;
  user-select: none;
}

.page-btns {
  width: 100%;
}

.page-btn {
  color: white;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #c9a67c;
  }
}

.page-index {
  width: 20%;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

@include md {
  .select {
    font-size: 0.8rem;
  }

  .full-content {
    width: 100%;
  }

  .btn.type-btn {
    letter-spacing: 0.1rem;
  }

  .option-modal {
    border-radius: 5px;
    z-index: 1;

    .label {
      padding: 0;
      font-size: 0.7rem;
    }

    .option {
      font-size: 0.8rem;
    }
  }

  .table-row {
    background-color: #313131;
    color: #999;
    padding: 0.5rem;

    &:hover {
      background-color: #1d1d1d;
      color: white;
    }
  }

  .row-record {
    font-size: 0.7rem;
  }

  .bottom {
    width: 100%;
    position: relative;
    margin-top: 1rem;

    .hint {
      width: 100%;
    }
  }

  .pages {
    font-size: 0.8rem;
    display: block;
  }

  .page-btns {
    justify-content: center;
  }

  .page-index {
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
    text-align: center;
  }
}
</style>
